<template>
  <div class="image-selector--child" v-on:click="changePicture">
    <div class="image-selector--child-image" v-if="type === 'character'">
      <character-image :center="true"></character-image>
    </div>
    <div class="image-selector--child-image" v-else>
      <img
        v-if="productType === 'tshirt'"
        class="merch-item-image-item"
        src="@/assets/img/tshirt.png"
      />
      <img
        v-else-if="productType === 'sweatshirt'"
        class="merch-item-image-item"
        src="@/assets/img/sweatshirt.png"
      />
      <div>
        <character-image
          :center="true"
          size="contain"
          :top="-10"
          :specificSize="{ width: '15px' }"
        ></character-image>
      </div>
    </div>
  </div>
</template>

<script>
import CharacterImage from './CharacterImage.vue';

export default {
  name: 'ImageSelector',

  components: { CharacterImage },

  props: {
    type: String,
    url: String,
  },

  data: () => ({
    productType: 'tshirt',
  }),

  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queries = Object.fromEntries(urlSearchParams.entries());

    if (queries.product) {
      this.productType = (queries.product === 'tshirt') ? 'tshirt' : 'sweatshirt';
    }

    this.$root.$on('update:product:type', (type) => {
      this.productType = type;
    });
  },

  methods: {
    changePicture() {
      this.$root.$emit(
        'canvas:update:picture',
        (this.type === 'character') ? 'character' : this.productType,
      );
    },
  },
};
</script>

<style>

.image-selector--child {
  background-color: #a7e6d9;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.image-selector--child > div > div {
  display: flex;
  justify-content: center;
}

.image-selector--child.active {
  border-radius: 2px solid #fff;
}

.image-selector--child-image {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector--child-image > img {
  height: 100%;
  position: absolute;
}

.image-selector--child-image > * > img {
  height: 100%;
  width: auto !important;
}

</style>
