<template>
  <div class="pb-4">
    <div class="selector--child h-100 d-flex" :class="{ 'selector--child-selected': open }">
      <div class="position-relative">
        <label v-on:click="switchOpen()" class="h-100">
          <icon @load="checkLoad" :path="category.icon"></icon>
        </label>
        <span class="subtext">{{ category.text }}</span>
      </div>
      <div :class="{ 'd-none': !open }" class="selector--child-nth h-100 position-relative">
        <div
          class="position-relative"
          v-for="sub in category.subCategory"
          :key="sub.icon"
        >
          <label
            v-on:click="selectIcon(sub.icon)"
            class="h-100"
            :class="{
              'selector--child-nth-selected': sub.icon === selected
            }"
          >
            <icon @load="checkLoad" :path="sub.icon"></icon>
          </label>
          <span class="subtext mini">{{ sub.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'Selected',

  components: { Icon },

  props: {
    category: Object,
  },

  data: () => ({
    selected: false,
    open: false,
    imgLoad: -1,
  }),

  created() {
    this.$root.$on('select:open', (value) => {
      if (value.icon === this.category.icon || value.state === false) return;
      this.open = false;
    });

    this.$root.$on('select:open:child', (value) => {
      if (!value) return;
      if (!this.category.subCategory) return;

      const sub = this.category.subCategory.find((e) => value.icon === e.icon);

      this.selected = sub ? this.selected : false;
    });
  },

  methods: {
    checkLoad(evt) {
      this.imgLoad += 1;

      if (this.imgLoad === this.category.subCategory.length) {
        this.$emit('load', evt);
      }
    },

    switchOpen() {
      if (
        !this.category.subCategory
        || (
          this.category.subCategory
          && this.category.subCategory.length < 1
        )
      ) {
        this.$root.$emit('select:open:child', {
          category: this.category.name,
          index: this.category.index,
          icon: this.category.icon,
          type: 'category',
          name: this.category.name,
        });
      }

      this.open = !this.open;

      setTimeout(() => this.$emit('update'), 1);

      if (!this.open) {
        this.selected = false;
      }
    },

    selectIcon(icon) {
      this.selected = icon;
    },
  },

  watch: {
    open(value) {
      this.$root.$emit('select:open', {
        icon: this.category.icon,
        state: value,
      });
    },

    selected(value) {
      this.$root.$emit('select:open:child', this.category.subCategory.find((e) => e.icon === value));
    },
  },
};
</script>

<style>

.selector--child {
  background-color: #ededed;
  margin: 0 10px;
  border-radius: 20px;
  filter: grayscale(1);
}

.selector--child-selected {
  filter: grayscale(0);
}

.selector--child-selected label {
  border: 2px solid #000;
  border-radius: 12px;
}

.selector--child-nth-selected {
  border: 2px solid #000;
  border-radius: 10px;
}

.selector--child-nth:empty {
  padding: 0px;
}

.selector--child-nth {
  display: flex;
  padding: 10px;
}
.selector--child-nth label {
  margin: 0 5px;
  filter: grayscale(1);
}

label.selector--child-nth-selected {
  filter: grayscale(0);
}

.selector--child-selected > div > .subtext, .selector--child-nth-selected ~ .subtext {
  font-weight: 600;
}

.subtext {
  display: block;
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}

.subtext.mini {
  bottom: -20px;
  font-size: 13px;
}

</style>
