<template>
  <div class="asset--selector">
    <div
      v-if="assets.length > 0"
      class="arrow left"
      :class="{ disable: !scrollLeft }"
      v-on:click="scroll('left')"
    >
      <i class="fa fa-chevron-left"></i>
    </div>
    <label
      v-if="isDeletable()"
      class="asset--selector-asset remove"
      v-on:click="removeCurrentAsset"
    >
      <icon @load="checkLoad" path="icons/remove.png"></icon>
    </label>
    <div
      ref="scroll"
      class="asset--selector-container d-flex w-100"
      v-on:load="updateScroll"
      v-on:mousedown="touchDown"
      v-on:mouseup="touchUp"
      v-on:touchstart="touchDown"
      v-on:touchend="touchUp"
    >
      <label
        class="asset--selector-asset"
        v-for="asset in assets"
        :key="asset.id"
        v-on:click="selectAsset(asset)"
        :class="{
          checked: selected === asset.id
        }"
      >
        <icon @load="checkLoad" :path="getAssetPath(asset)"></icon>
      </label>
    </div>
    <div
      v-if="assets.length > 0"
      class="arrow right"
      :class="{ disable: !scrollRight }"
      v-on:click="scroll('right')"
    >
      <i class="fa fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as MutationTypes from '@/store/mutation-types';
import comparaison from '@/assets/comparaison-table.json';
import Asset from '@/models/asset';
import Variant from '@/models/variant';
import Icon from './Icon.vue';

export default {
  components: { Icon },
  name: 'AssetSelector',

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    comparaison,
    assets: [],
    selected: null,
    scrollLeft: false,
    scrollRight: false,
    imgLoad: -1,
    currentValue: null,
    scrollState: false,
    position: 0,
    lastTouch: null,
  }),

  created() {
    window.addEventListener('mousemove', this.movement.bind(this));
    window.addEventListener('touchmove', this.movement.bind(this));

    this.$root.$on('assets:update', () => {
      this.defineAssets(this.currentValue);
    });

    this.$root.$on('select:open:child', (value) => {
      this.currentValue = value;

      if (!value) return;

      this.defineAssets(value);
    });
  },

  async updated() {
    this.imgLoad = 0;
  },

  methods: {
    movement(evt) {
      if (!this.scrollState || !this.$refs.scroll) return;
      if (evt.type === 'touchmove') {
        const [touch] = evt.touches;
        this.$refs.scroll.scrollLeft -= touch.pageX - (this.lastTouch || touch.pageX);
        this.lastTouch = touch.pageX;
      } else {
        this.$refs.scroll.scrollLeft -= evt.movementX;
      }

      this.updateScroll();
    },

    touchDown() {
      this.scrollState = true;
    },

    touchUp() {
      this.lastTouch = false;
      this.scrollState = false;
    },

    defineAssets(value) {
      const assets = this.getAssetsFromCategory(value);

      this.assets = assets.map((asset) => {
        if (asset.subCategory === '2_mouths' || asset.subCategory === '3_noses') {
          const { colorId } = this.character.skin;

          const variants = this.get({
            type: 'Variant',
            parent: asset._id,
          });

          const variant = variants.find((v) => Number(v.color) === colorId);

          if (variant) {
            return new Asset({
              ...asset,
              activeVariant: new Variant(variant),
            });
          }
        }

        return new Asset({
          ...asset,
        });
      });
    },

    isDeletable() {
      const asset = this.assets[0];

      if (!asset) return false;

      return (
        asset.category !== 'clothes'
        && asset.subCategory !== 'background'
        && asset.subCategory !== '3_noses'
        && asset.subCategory !== '2_mouths'
        && asset.subCategory !== '1_eyes'
        && asset.subCategory !== 'eyebrows'
      );
    },

    checkLoad() {
      this.imgLoad += 1;

      if (this.imgLoad === this.assets.length) {
        this.updateScroll();
      }
    },

    scroll(direction) {
      if (direction === 'left') {
        this.$refs.scroll.scrollLeft -= 95;
      } else {
        this.$refs.scroll.scrollLeft += 95;
      }

      this.updateScroll();
    },

    updateScroll() {
      if (this.$refs.scroll.offsetWidth < this.$refs.scroll.scrollWidth) {
        this.scrollLeft = true;
        this.scrollRight = true;
      }

      if (this.$refs.scroll.scrollLeft === 0) {
        this.scrollLeft = false;
      }
      if (
        this.$refs.scroll.scrollLeft >= (
          this.$refs.scroll.scrollWidth
          - this.$refs.scroll.offsetWidth
        )
      ) {
        this.scrollRight = false;
      }
    },

    removeCurrentAsset() {
      const asset = this.character.assets.find((e) => e.subCategory === this.assets[0].subCategory);

      if (!asset) return;

      this.$store.dispatch(MutationTypes.DELETE_ASSET, asset);
      this.selected = null;
      this.$root.$emit('asset:change', null);
      this.storeNewCharacter();
    },

    getAssetPath(asset) {
      if (asset.activeVariant) {
        return asset.activeVariant.icon;
      }

      return asset.icon;
    },

    selectAsset(asset) {
      if (
        asset.id === this.selected
        && (
          asset.category !== 'clothes'
          && asset.subCategory !== 'background'
          && asset.subCategory !== '3_noses'
          && asset.subCategory !== '2_mouths'
          && asset.subCategory !== '1_eyes'
          && asset.subCategory !== 'eyebrows'
        )
      ) {
        this.selected = null;
        this.$store.dispatch(MutationTypes.DELETE_ASSET, asset);
        this.$root.$emit('asset:change', null);
        return;
      }

      this.selected = asset.id;
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, asset);

      if (asset.subCategory === '2_mouths' || asset.subCategory === '3_noses') return;
      this.$root.$emit('asset:change', asset);
      this.storeNewCharacter();
    },

    getAssetsFromCategory(category) {
      if (!category) return [];

      if (category.category === 'clothes') {
        return this.get({
          type: 'Asset',
          subCategory: category.name,
          gender: this.character.gender,
          anatomy: this.character.anatomy,
        });
      }

      if (category.category === '2_mouths') {
        return this.get({
          type: 'Asset',
          subCategory: category.name,
        });
      }

      return this.get({
        type: 'Asset',
        subCategory: category.name,
      });
    },
  },

  watch: {
    scrollState() {

    },
  },
};
</script>

<style>

.asset--selector {
  order: 4;
  width: 100%;
  max-width: 100%;
  height: 99px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.asset--selector-container {
  overflow: hidden;
}

.asset--selector-asset.remove {
  margin: 0 10px;
  border-radius: 10px;
  border: 0px solid #ededed;
  padding: 0px;
}

.asset--selector-asset {
  cursor: pointer;
  background-color: #ededed;
  border: 3px solid #ededed;
  height: 100%;
  width: 79px;
  min-width: 79px;
  margin: 0 10px;
  border-radius: 20px;
  padding: 10px;
}

.asset--selector-asset.checked {
  border: 3px solid #144d34;
}

@media screen and (max-width: 1160px) {
  .merch .asset--selector {
    height: auto;
    margin-top: 1em;
  }

  .merch .asset--selector-asset {
    height: 64px !important;
    width: 64px;
    min-width: 64px !important;
    margin: 0 5px;
  }
}

@media screen and (max-width: 870px) {
  .asset--selector {
    height: auto;
    margin-top: 1em;
  }

  .asset--selector-asset {
    height: 64px !important;
    width: 64px;
    min-width: 64px !important;
    margin: 0 5px;
  }
}

</style>
