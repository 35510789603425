import axios from 'axios';
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:3000/';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Accept: 'application/ld+json',
};

const instance = axios.create({
  baseURL: API_URL,
  headers,
  paramsSerializer: (params) => qs.stringify(params),
});

// https://github.com/axios/axios#interceptors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // If expired JWT token
    if (
      error.response
      && error.response.status === 401
      && error.response.data.message === 'Expired JWT Token'
    ) {
      delete localStorage.token;
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

export default instance;
