export const uniqueArray = (ar) => {
  const j = {};

  ar.forEach((v) => {
    j[`${v}::${typeof v}`] = v;
  });

  return Object.keys(j).map((v) => j[v]);
};

export const helper = 'helper';
