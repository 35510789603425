import { mapState } from 'vuex';
import { uniqueArray } from '@/assets/helper';
import database from '@/assets/database.json';
import * as MutationTypes from '@/store/mutation-types';
import Asset from '../models/asset';
import Variant from '../models/variant';

export default {
  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    db: database,
  }),

  methods: {
    put(data) {
      const _id = Math.round(Math.random() * 1e15).toString(36);

      if (data.where && data.update) {
        const update = this.get(data.where);
        return (this.db[update._id] = { ...update, ...data.update });
      }

      return (this.db[_id] = { _id, ...data });
    },

    get(where) {
      return Object.values(this.db).filter((e) => this.compare(where, e).every((b) => b));
    },

    compare(where, obj) {
      return Object.entries(where)
        .map((w) => {
          const [key, value] = w;

          if (where.OR) delete where.OR;

          if (!obj[key]) return false;

          return value !== null && typeof value === 'object'
            ? this.compare(value, obj[key]).every((e) => e)
            : obj[key] === value;
        })
        .concat([where.OR ? this.compareOR(where.OR, obj) : true]);
    },

    compareOR(where, obj) {
      return Object.entries(where)
        .map((w) => {
          const [key, value] = w;
          return obj[key] === value;
        })
        .some((b) => b);
    },

    getCategories() {
      return uniqueArray(
        Object.values(this.db)
          .map((e) => e.category)
          .filter((b) => b),
      );
    },

    getSubCategories() {
      return uniqueArray(
        Object.values(this.db)
          .map((e) => e.subCategory)
          .filter((b) => b),
      );
    },

    getRandomGender() {
      return ['male', 'female'][Math.floor(Math.random() * 2)];
    },

    getRandomAnatomy() {
      return ['fat', 'medium', 'skinny'][Math.floor(Math.random() * 3)];
    },

    minifyAsset(asset) {
      const miniAsset = { _id: asset._id };
      if (asset.activeVariant) {
        miniAsset.activeVariant = { _id: asset.activeVariant._id };
      }
      return miniAsset;
    },

    deminifyAsset(asset) {
      const [deminifiedAsset] = this.get({
        type: 'Asset',
        _id: asset._id,
      });

      if (asset.activeVariant) {
        const [variant] = this.get({
          type: 'Variant',
          parent: asset._id,
          _id: asset.activeVariant._id,
        });
        deminifiedAsset.activeVariant = new Variant(variant);
      } else {
        deminifiedAsset.activeVariant = null;
      }

      return new Asset(deminifiedAsset);
    },

    characterMinifier(char) {
      const chara = JSON.parse(JSON.stringify(char));

      chara.skin.assets = char.skin.assets.map(this.minifyAsset);
      chara.assets = char.assets.map(this.minifyAsset);

      return chara;
    },

    characterDeminifier(char) {
      char.skin.assets = char.skin.assets.map(this.deminifyAsset);
      char.assets = char.assets.map(this.deminifyAsset);

      return char;
    },

    storeNewCharacter() {
      const minifiedCharacter = this.characterMinifier(this.character);

      localStorage.setItem('character', JSON.stringify(minifiedCharacter));

      const date = new Date();
      date.setTime((new Date()).getTime() + (365 * 24 * 60 * 60 * 1000));

      document.cookie = `character=${
        JSON.stringify(minifiedCharacter)
      }; expires=${
        date.toUTCString()
      }; path=/`;
    },

    getRandomSkinByGenderAndAnatomy(gender, anatomy) {
      const skin = this.get({ category: 'bodies', gender, anatomy });
      return skin[
        Math.floor(
          Math.random() * (skin.length - skin.length / 2) + Math.random() * (skin.length / 2),
        )
      ];
    },

    generateRandomVariantSkin(subCategory) {
      const { colorId } = this.character.skin;

      const random = this.get({ type: 'Asset', subCategory });
      const asset = new Asset(random[Math.floor(Math.random() * random.length)]);

      const variants = this.get({
        type: 'Variant',
        parent: asset._id,
      });

      const variant = variants.find((v) => Number(v.color) === colorId);

      if (variant) asset.activeVariant = variant;

      return asset;
    },

    generateRandomEyebrowsAndHair() {
      const random = this.get({ type: 'Asset', subCategory: 'eyebrows' });
      const asset = new Asset(random[Math.floor(Math.random() * random.length)]);

      const variants = this.get({
        type: 'Variant',
        parent: asset._id,
      });

      if (variants.length <= 0) {
        return this.generateRandomEyebrowsAndHair();
      }

      const index = Math.floor(Math.random() * variants.length);

      const variant = new Variant(variants[index]);

      asset.activeVariant = variant;

      const randomhair = this.get({ type: 'Asset', category: 'haircuts' });
      const assethair = new Asset(randomhair[Math.floor(Math.random() * randomhair.length)]);

      const variantsHair = this.get({
        type: 'Variant',
        parent: assethair._id,
      });
      const variantHair = new Variant(variantsHair[index]);

      assethair.activeVariant = variantHair;

      return [asset, assethair];
    },

    generateRandomClothes() {
      const random = this.get({
        type: 'Asset',
        category: 'clothes',
        gender: this.character.gender,
        anatomy: this.character.anatomy,
      });

      const asset = new Asset(random[Math.floor(Math.random() * random.length)]);

      const variants = this.get({
        type: 'Variant',
        parent: asset._id,
      });

      const variant = variants[Math.floor(Math.random() * variants.length)];

      if (variant) asset.activeVariant = variant;

      return asset;
    },

    generateRandomAsset(subCategory) {
      let random = this.get({ type: 'Asset', subCategory });
      random = random.length > 0 ? random : this.get({ type: 'Asset', category: subCategory });

      const asset = new Asset(random[Math.floor(Math.random() * random.length)]);

      const variants = this.get({
        type: 'Variant',
        parent: asset._id,
      });

      const variant = variants[Math.floor(Math.random() * variants.length)];

      if (variant) asset.activeVariant = variant;

      return asset;
    },

    generateRandomAssetWithNone(subCategory) {
      let random = this.get({ type: 'Asset', subCategory });
      random = random.length > 0 ? random : this.get({ type: 'Asset', category: subCategory });

      const rand = Math.floor(Math.random() * (random.length * 2));

      if (rand >= random.length) return false;

      const asset = new Asset(random[rand]);

      const variants = this.get({
        type: 'Variant',
        parent: asset._id,
      });

      const variant = variants[Math.floor(Math.random() * variants.length)];

      if (variant) asset.activeVariant = variant;

      return asset;
    },

    getRandom() {
      const mouth = this.generateRandomVariantSkin('2_mouths');
      const nose = this.generateRandomVariantSkin('3_noses');

      this.$store.dispatch(MutationTypes.UPDATE_ASSET, mouth);
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, nose);

      const [eyebrows, hair] = this.generateRandomEyebrowsAndHair();

      this.$store.dispatch(MutationTypes.UPDATE_ASSET, eyebrows);
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, hair);

      const eyes = this.generateRandomAsset('1_eyes');
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, eyes);

      const cup = this.generateRandomAsset('12_cup');
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, cup);

      const pens = this.generateRandomAsset('1_pens');
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, pens);

      const background = this.generateRandomAsset('background');
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, background);

      const pets = this.generateRandomAssetWithNone('pets');
      if (pets) this.$store.dispatch(MutationTypes.UPDATE_ASSET, pets);

      const bracelets = this.generateRandomAssetWithNone('10_bracelets');
      if (bracelets) this.$store.dispatch(MutationTypes.UPDATE_ASSET, bracelets);

      const watches = this.generateRandomAssetWithNone('11_watches');
      if (watches) this.$store.dispatch(MutationTypes.UPDATE_ASSET, watches);

      const tattoos = this.generateRandomAssetWithNone('7_tattoos');
      if (tattoos) this.$store.dispatch(MutationTypes.UPDATE_ASSET, tattoos);

      const necklace = this.generateRandomAssetWithNone('9_necklaces');
      if (necklace) this.$store.dispatch(MutationTypes.UPDATE_ASSET, necklace);

      const plush = this.generateRandomAssetWithNone('3_plush');
      if (plush) this.$store.dispatch(MutationTypes.UPDATE_ASSET, plush);

      const clothes = this.generateRandomClothes();
      this.$store.dispatch(MutationTypes.UPDATE_ASSET, clothes);

      this.storeNewCharacter();
    },

    getSubCategoriesByCategory(category) {
      return Object.values(this.db)
        .map((e) => e.category === category && e.subCategory)
        .filter((b) => b);
    },
  },
};
