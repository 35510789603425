import comparaisonTable from '../assets/comparaison-table.json';

export default class Asset {
  constructor({
    _id,
    id,
    path,
    icon,
    thumbnail,
    category,
    subCategory = null,
    gender = null,
    anatomy = null,
    name = null,
    activeVariant = null,
    variants = [],
    createdAt = new Date(),
  }) {
    this._id = _id;
    this.id = id;
    this.path = path;
    this.icon = icon;
    this.thumbnail = thumbnail;
    this.category = category;
    this.subCategory = subCategory;
    this.gender = gender;
    this.anatomy = anatomy;
    this.name = name;
    this.activeVariant = activeVariant;
    this.variants = variants;
    this.createdAt = createdAt;
    this.index = this.getIndex(category, subCategory);
  }

  getIndex(category, subCategory) {
    if (comparaisonTable.categories[subCategory]) {
      return comparaisonTable.categories[subCategory].index;
    }

    const out = Object.values(comparaisonTable.categories)
      .find((e) => e.name === category);

    if (out) {
      return out.index;
    }

    return null;
  }
}
