var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.character)?_c('div',{staticClass:"character--image",class:( _obj = { 'character--image-left': !_vm.center }, _obj[_vm.size] = _vm.size, _obj ),style:({
    'left': _vm.left,
    'top': (_vm.top + "px"),
    'width': _vm.specificSize.width,
    'height': ((_vm.specificSize.height) + "px")
  })},[_c('img',{staticStyle:{"z-index":"3"},attrs:{"src":"https://s3.amazonaws.com/static.lofigirl.com/builder/more/chair_thumbnail.png"}}),_c('img',{staticStyle:{"z-index":"2"},attrs:{"src":"https://s3.amazonaws.com/static.lofigirl.com/builder/more/room_thumbnail.png"}}),_vm._l((_vm.character.assets.concat(_vm.character.skin.assets).filter(function (e) { return e.id; })),function(asset){return _c('img',{key:asset.id,style:({ 'z-index': asset.index }),attrs:{"id":asset._id,"src":("https://s3.amazonaws.com/static.lofigirl.com/builder/" + (_vm.getAssetPath(asset)))}})})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }