export default class Variant {
  constructor({
    _id,
    id,
    path,
    icon,
    thumbnail,
    parent,
    color,
  }) {
    this._id = _id;
    this.id = id;
    this.path = path;
    this.icon = icon;
    this.thumbnail = thumbnail;
    this.parent = parent;
    this.color = color;
  }
}
