<template>
  <span>
    <img
      v-if="icon"
      @load="$emit('load')"
      class="icon"
      :class="{ 'n-draggable': !draggable }"
      :src="getIconURL()"
      :draggable="draggable"
    >
    <img
      v-if="path"
      @load="$emit('load')"
      class="icon"
      :class="{ 'n-draggable': !draggable }"
      :src="getPathURL()"
      :draggable="draggable"
    >
    <span
      v-else-if="color"
      class="skin-color"
      :style="{
        'background-color': color,
      }"
    ></span>
  </span>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    size: String,
    icon: String,
    path: String,
    color: String,
    draggable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getPathURL() {
      return `https://s3.amazonaws.com/static.lofigirl.com/builder/${this.path}`;
    },

    getIconURL() {
      return `https://s3.amazonaws.com/static.lofigirl.com/builder/icons/${this.icon}.png`;
    },
  },
};
</script>

<style>
.icon {
  height: 100%;
  width: -webkit-fill-available;
}

.skin-color {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  display: block;
  cursor: pointer;
}

.n-draggable {
  pointer-events: none;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (max-width: 870px) {
  .skin-color {
    width: 20px;
    height: 20px;
  }
}

</style>
