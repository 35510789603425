<template>
  <div class="modal" v-if="open">
    <div class="modal-dropdown" v-on:click="close"></div>
    <div class="modal-container">
      <i class="fas fa-times" v-on:click="close"></i>
      <back-head :name="backhead">
        <div class="modal-title"><h1>{{ title }}</h1></div>
      </back-head>
      <div class="w-100 pb-8 pt-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BackHead from './BackHead.vue';

export default {
  components: { BackHead },
  name: 'Modal',

  props: {
    name: {
      type: String,
      required: true,
    },
    title: String,
    backhead: String,
  },

  data: () => ({
    open: false,
  }),

  created() {
    this.$root.$on(`modal:${this.name}:open`, (state) => this.open = state);
    this.$root.$on(`modal:${this.name}:open:true`, () => this.open = true);
    this.$root.$on(`modal:${this.name}:open:false`, () => this.open = false);
  },

  methods: {
    close() {
      this.open = false;
    },
  },
};
</script>

<style scoped>
.pb-8 { padding-bottom: 6em; }

.modal {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-dropdown {
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3)
}

.modal-container {
  text-align: center;
  position: relative;
  z-index: 101;
  width: 50%;
  border-radius: 0.8em;
  background-color: #fff;
} .modal-container > i {
  color: #fff;
  position: absolute;
  top: -40px;
  font-size: 1.5em;
  right: 0;
  cursor: pointer;
}

@media screen and (max-width: 1170px) {
  .modal-container {
    width: 75%;
  }
}

@media screen and (max-width: 870px) {
  .modal-container {
    width: 90%;
  }

  .pb-8 { padding-bottom: 3em; }
}
</style>
