<template>
  <div class="d-flex justify-content-center color-container">
    <div class="color">
      <span
        class="color-select"
        v-for="variant in variants"
        :key="variant.id"
      >
        <input type="radio" :id="variant.id" :value="variant.id" v-model="variantSelect">
        <label
          v-on:click="selectVariant(variant)"
          :for="variant.id"
          :class="{ checked: variantSelect === variant.id }
        ">
          <icon :color="`#${variant.color}`"></icon>
        </label>
      </span>
    </div>
  </div>
</template>

<script>
import Variant from '@/models/variant';
import * as ActionNames from '@/store/action-names';
import Icon from './Icon.vue';

export default {
  name: 'ColorSelector',

  components: { Icon },

  data: () => ({
    asset: null,
    variants: [],
    variantSelect: null,
    types: {
      1: '#fadfd0',
      2: '#f8d2b6',
      3: '#f4bb93',
      4: '#d9ab7a',
      5: '#d39f67',
      6: '#ca8f58',
      7: '#c0814b',
      8: '#b17039',
      9: '#a45d2c',
      10: '#9d4e24',
      11: '#94481e',
      12: '#8a401a',
      13: '#7f3a16',
      14: '#6e3213',
      15: '#5c280c',
      16: '#512209',
      17: '#461c05',
      18: '#391500',
      19: '#2b1000',
      20: '#1e0b00',
    },
  }),

  created() {
    this.$root.$on('select:open:child', () => {
      this.variants = [];
    });

    this.$root.$on('asset:change', (value) => {
      if (value) this.asset = value;
      if (!value) return this.variants = [];

      let variants = this.get({
        type: 'Variant',
        parent: value._id,
      });

      if (variants.length > 0) {
        variants = [{ ...value, color: value.path.replace(/(.*)\//g, '').replace('.png', ''), variants: undefined }].concat(variants);
      }

      return this.variants = variants;
    });
  },

  methods: {
    selectVariant(variant) {
      const rvariant = new Variant(variant);
      this.asset.activeVariant = rvariant;
      this.$store.dispatch(ActionNames.UPDATE_ASSET, this.asset);
      this.storeNewCharacter();
    },
  },
};
</script>

<style>

.color-container {
  order: 3;
}

.color:empty {
  background: none;
}

.color {
  height: 33px;
  display: flex;
  border-radius: 60px;
  padding: 7px 7px 0px 7px;
  background-color: #eeeeee;
}

.color-select input {
  display: none;
}

.color-select label {
  margin: 0 2px;
  border: 2px solid #eeeeee;
  border-radius: 20px;
  margin-bottom: 0;
}
.color-select label.checked {
  border: 2px solid black;
}

@media screen and (max-width: 1160px) {
  .merch .color {
    height: auto;
    flex-wrap: wrap;
    border-radius: 15px;
    justify-content: center;
  }
  .merch .color-container {
    order: 5;
  }
}

@media screen and (max-width: 870px) {
  .color {
    height: auto;
    flex-wrap: wrap;
    border-radius: 15px;
    justify-content: center;
  }
  .color-container {
    order: 5;
  }
}

</style>
