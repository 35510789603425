<template>
  <div class="character">
    <div class="canvas canvas-rounded" v-if="character">
      <character-image v-if="productType === 'character'" :character="character"></character-image>
      <div class="canvas--picture" v-else>
        <img
          v-if="productType === 'tshirt'"
          class="merch-item-image-item-canvas"
          src="@/assets/img/tshirt.png"
        />
        <img v-else class="merch-item-image-item-canvas" src="@/assets/img/sweatshirt.png" />
        <character-image
          :center="true"
          size="contain"
          :top="200"
          :specificSize="{ width: '17%' }"
        ></character-image>
      </div>
      <div v-if="isMerch" class="image-selector">
        <image-selector type="character"></image-selector>
        <image-selector></image-selector>
      </div>
    </div>

    <div v-if="!isMerch">
      <download-character></download-character>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownloadCharacter from './DownloadCharacter.vue';
import CharacterImage from './CharacterImage.vue';
import ImageSelector from './ImageSelector.vue';

export default {
  name: 'Character',

  components: { DownloadCharacter, CharacterImage, ImageSelector },

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  props: {
    isMerch: Boolean,
  },

  data: () => ({
    output: null,
    productType: 'character',
  }),

  created() {
    this.$root.$on('canvas:update:picture', (type) => {
      this.productType = type;
    });

    this.$root.$on('character:upload', async (id) => {
      try {
        const response = await this.$http.post('users', { email: id, character: JSON.stringify(this.character) });
        const uuid = response.data.id;
        const url = `${process.env.VUE_APP_API_URL}/users/character/${uuid}.jpg`;
        window.parent.postMessage({ type: 'upload', success: 'success', url }, '*');
      } catch (error) {
        window.parent.postMessage({ type: 'upload', code: 500, error: 'INTERNAL_SERVER_ERROR' }, '*');
      }
    });
  },
};
</script>

<style>

.merch-item-image-item-canvas {
  top: 0 !important;
  width: auto !important;
  height: 100%;
}

.canvas--picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a6e5d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas--picture > img {
  position: absolute;
  top: -250px;
  width: 100%;
}

.image-selector {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  z-index: 300;
  width: auto;
  padding: 10px;
}

.image-selector > div {
  margin: 10px;
}

.character {
  order: 2;
  position: sticky;
  top: 10px;
  z-index: 998;
}

.dl-canvas-parent {
  height: 0;
  width: 0;
}

.dl-canvas {
  position: fixed;
  top: -1080px;
  left: -3500px;
  pointer-events: none;
}

.canvas-rounded {
  border-radius: 20px;
}

.canvas {
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 1450px) {
  .canvas--picture > img {
    top: -150px;
  }
}

@media screen and (max-width: 1100px) {
  .canvas--picture > img {
    top: -100px;
  }
}

@media screen and (max-width: 1160px) {
  .merch .character {
    order: -1;
  }

  .merch .canvas--picture > img {
    top: -150px;
  }
}

@media screen and (max-width: 870px) {
  .character {
    order: -1;
  }

  .canvas--picture > img {
    top: -150px;
  }
}

@media screen and (max-width: 600px) {
  .canvas--picture > img {
    top: -80px;
  }
}

@media screen and (max-width: 450px) {
  .canvas--picture > img {
    top: -50px;
  }
}

@media screen and (max-width: 390px) {
  .canvas--picture > img {
    top: -10px;
  }
}

</style>
