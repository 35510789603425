import { uniqueArray } from '@/assets/helper';
import * as MutationTypes from './mutation-types';
import * as ActionNames from './action-names';
import Character from '../models/character';
import comparaisonTable from '../assets/comparaison-table.json';
import Asset from '../models/asset';

export default {
  state: {
    character: null,
  },
  mutations: {
    [MutationTypes.CREATE](state, payload) {
      return (state.character = new Character({
        id: 1,
        ...payload,
      }));
    },

    [MutationTypes.UPDATE_ASSET](state, payload) {
      const { index } = comparaisonTable.categories[payload.subCategory] || { index: null };

      const { assets } = state.character;

      const currentAssetAtIndex = assets.findIndex((e) => e.index === index) !== -1
        ? assets.findIndex((e) => e.index === index)
        : assets.length;

      assets.splice(currentAssetAtIndex, 1, new Asset({ ...payload }));

      return (state.character.assets = assets);
    },

    [MutationTypes.DELETE_ASSET](state, payload) {
      const { assets } = state.character;

      const asset = assets.findIndex((e) => e._id === payload._id);

      if (!asset) return;

      assets.splice(asset, 1);

      state.character.assets = assets;
    },

    [MutationTypes.RESET_ASSET](state) {
      const assets = [...state.character.assets];

      assets.reverse().forEach((asset) => {
        if (
          asset.category !== 'clothes'
          && asset.subCategory !== 'background'
          && asset.subCategory !== '3_noses'
          && asset.subCategory !== '2_mouths'
          && asset.subCategory !== '1_eyes'
          && asset.subCategory !== 'eyebrows'
        ) {
          const index = state.character.assets.findIndex((ass) => ass.id === asset.id);

          state.character.assets.splice(index, 1);
        }
      });

      state.character.assets.splice(0, 0);
    },

    [MutationTypes.UPDATE_ANATOMY](state, { skin, anatomy }) {
      state.character.anatomy = anatomy;
      state.character.skin = skin;
    },

    [MutationTypes.UPDATE_GENDER](state, { skin, gender }) {
      state.character.gender = gender;
      state.character.skin = skin;
    },

    [MutationTypes.UPDATE_SKIN](state, skin) {
      state.character.skin = skin;
    },
  },
  getters: {
    isFemale: (state) => state.character.gender === 'female',
    isMale: (state) => state.character.gender === 'male',
    isFat: (state) => state.character.gender === 'fat',
    isMedium: (state) => state.character.gender === 'medium',
    isSkinny: (state) => state.character.gender === 'skinny',

    getCharacterSkin: (state) => state.character.skin,
    getCharacterGender: (state) => state.character.gender,
    getCharacterAnatomy: (state) => state.character.anatomy,

    getCharacterSubCategories: (state) => uniqueArray(
      state.character.assets.map((e) => e.subCategories),
    ),
  },
  actions: {
    [ActionNames.CREATE]({ commit }, payload) {
      commit(MutationTypes.CREATE, payload);
    },

    [ActionNames.UPDATE_ASSET]({ commit }, payload) {
      commit(MutationTypes.UPDATE_ASSET, payload);
    },

    [ActionNames.DELETE_ASSET]({ commit }, payload) {
      commit(MutationTypes.DELETE_ASSET, payload);
    },

    [ActionNames.RESET_ASSET]({ commit }) {
      commit(MutationTypes.RESET_ASSET);
    },

    [ActionNames.UPDATE_ANATOMY]({ commit }, payload) {
      commit(MutationTypes.UPDATE_ANATOMY, payload);
    },

    [ActionNames.UPDATE_GENDER]({ commit }, payload) {
      commit(MutationTypes.UPDATE_GENDER, payload);
    },

    [ActionNames.UPDATE_SKIN]({ commit }, payload) {
      commit(MutationTypes.UPDATE_SKIN, payload);
    },
  },
};
