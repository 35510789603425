<template>
  <div ref="canvasParent" class="dl-canvas-parent">
    <div ref="canvas" class="canvas dl-canvas" v-if="character">
      <img style="z-index: 3" src="https://s3.amazonaws.com/static.lofigirl.com/builder/more/chair.png">
      <img style="z-index: 2" src="https://s3.amazonaws.com/static.lofigirl.com/builder/more/room.png">
      <img
        v-for="asset in assets"
        :key="asset.id"
        :src="`https://s3.amazonaws.com/static.lofigirl.com/builder/${getAssetPath(asset)}`"
        :style="{ 'z-index': asset.index }"
        @load="imageLoad"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DownloadCharacter',

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    assets: [],
    needPrint: false,
    loaded: 0,
  }),

  created() {
    this.$root.$on('character:print', (name) => {
      this.assets = this.getAssets();

      this.needPrint = name;
    });

    this.$root.$on('character:get:blob', () => this.getImage());
  },

  methods: {
    imageLoad() {
      this.loaded += 1;

      if (this.loaded >= this.assets.length) {
        this.loaded = 0;
        this.print(this.needPrint);
        this.needPrint = false;
      }
    },

    getAssets() {
      return this.character.assets.concat(this.character.skin.assets).filter((e) => e.id);
    },

    getAssetPath(asset) {
      if (asset.activeVariant) {
        return asset.activeVariant.path;
      }
      return asset.path;
    },

    async print(name) {
      this.$refs.canvasParent.style.width = `${window.screen.width > 720 ? window.screen.width : 720}px`;
      this.$refs.canvasParent.scrollTop = 0;
      this.$refs.canvas.scrollTop = 0;

      this.output = await this.$html2canvas(this.$refs.canvas, { logging: false, type: 'dataURL' });
      this.$refs.canvasParent.style.width = '0px';
      this.downloadURI(this.output, name);
      this.assets = [];
    },

    async getImage() {
      if (this.$refs.canvasParent) {
        this.$refs.canvasParent.style.width = `${window.screen.width > 720 ? window.screen.width : 720}px`;
        this.$refs.canvasParent.scrollTop = 0;
      }
      this.$refs.canvas.scrollTop = 0;

      const output = await this.$html2canvas(this.$refs.canvas, { logging: false, type: 'dataURL' });
      if (this.$refs.canvasParent) {
        this.$refs.canvasParent.style.width = '0px';
      }

      return output;
    },

    downloadURI(uri, name) {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style>

</style>
