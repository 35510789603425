export default class Character {
  constructor({
    id, gender, anatomy, skin, assets = [],
  }) {
    this.id = id;
    this.gender = gender;
    this.anatomy = anatomy;
    this.skin = skin;
    this.assets = assets;
  }
}
