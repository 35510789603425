<template>
  <div class="backhead" :class="{ [name]: true }">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#fff"
        fill-opacity="1"
        d="M0,160L60,181.3C120,203,240,245,360,256C480,
        267,600,245,720,224C840,203,960,181,1080,192C1200,
        203,1320,245,1380,266.7L1440,288L1440,320L1380,320C1320,
        320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,
        320,360,320C240,320,120,320,60,320L0,320Z"
      ></path>
    </svg>
    <div class="slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackHead',

  props: {
    name: String,
  },
};
</script>

<style scoped>

.backhead {
  position: relative;
  background: url("~@/assets/img/backhead-email.jpg");
  background-size: cover;
  color: rgb(253, 253, 253);
  padding: 10em 2em;
  border-radius: .8em .8em 0 0;
}

.browser {
  background: url("~@/assets/img/backhead-browser.jpg");
  background-position: center center;
}
.backhead::before {
  content: '';
  background-color: #000;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  border-radius: .8em .8em 0 0;
}

.backhead .slot {
  position: relative;
  z-index: 5;
}

.backhead svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}

@media screen and (max-width: 1160px) {
  .merch .backhead {
    padding: 6em 2em;
  }
}

@media screen and (max-width: 870px) {
  .backhead {
    padding: 6em 2em;
  }
}

</style>
