<template>
  <div
    v-if="character"
    class="character--image"
    :class="{ 'character--image-left': !center, [size]: size }"
    :style="{
      'left': left,
      'top': `${top}px`,
      'width': specificSize.width,
      'height': `${specificSize.height}px`
    }"
  >
    <img style="z-index: 3" src="https://s3.amazonaws.com/static.lofigirl.com/builder/more/chair_thumbnail.png">
    <img style="z-index: 2" src="https://s3.amazonaws.com/static.lofigirl.com/builder/more/room_thumbnail.png">
    <img
      v-for="asset in character.assets.concat(character.skin.assets).filter(e => e.id)"
      :id="asset._id"
      :key="asset.id"
      :src="`https://s3.amazonaws.com/static.lofigirl.com/builder/${getAssetPath(asset)}`"
      :style="{ 'z-index': asset.index }"
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CharacterImage',

  props: {
    center: Boolean,
    size: String,
    left: String,
    top: Number,
    specificSize: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  methods: {
    getAssetPath(asset) {
      if (asset.activeVariant) {
        return asset.activeVariant.thumbnail;
      }
      return asset.thumbnail;
    },
  },
};
</script>

<style scoped>

.character--image {
  width: 100%;
  height: 100%;
}

.character--image img {
  top: 0;
  width: 100%;
  position: absolute;
}

.character--image-left img {
  left: 0;
}

.contain {
  width: 100%;
  position: relative;
}
</style>
