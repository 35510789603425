<template>
  <div class="d-flex justify-space-between selector-height">
    <div
      class="arrow left"
      :class="{ disable: !scrollLeft }"
      v-on:click="scroll('left')"
    >
      <i class="fa fa-chevron-left"></i>
    </div>
    <div class="custom-container">
      <div
        ref="scroll"
        class="selector"
        v-on:load="updateScroll"
        v-on:mousedown="touchDown"
        v-on:mouseup="touchUp"
        v-on:touchstart="touchDown"
        v-on:touchend="touchUp"
      >
        <div class="d-flex heightforce">
          <selected
            @load="checkLoad"
            @update="updateScroll"
            v-for="category in categories"
            :key="category.icon"
            :category="category"
          ></selected>
        </div>
      </div>
    </div>
    <div
      class="arrow right"
      :class="{ disable: !scrollRight }"
      v-on:click="scroll('right')"
    >
      <i class="fa fa-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import comparaisonTable from '@/assets/comparaison-table.json';
import * as MutationTypes from '@/store/mutation-types';
import Selected from './Selected.vue';

export default {
  components: { Selected },
  name: 'Selector',

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    categories: [],
    scrollLeft: false,
    scrollRight: false,
    imgLoad: 0,
    scrollState: false,
    position: 0,
    lastTouch: null,
  }),

  created() {
    window.addEventListener('mousemove', this.movement.bind(this));
    window.addEventListener('touchmove', this.movement.bind(this));

    this.updateCategories();
  },

  methods: {
    updateCategories() {
      if (!this.character) return;

      const categories = Object.values(comparaisonTable.categories).filter((e) => e.type === 'category');

      this.categories = categories.map((c) => ({
        ...c,
        subCategory: Object.values(comparaisonTable.categories).filter((e) => {
          if (this.character.gender === 'male') {
            if (e.name === 'crop_top' || e.name === 'tank_tops') return false;
          }
          return e.type === 'subCategory' && e.category === c.name;
        }),
      }));
    },

    movement(evt) {
      if (!this.scrollState || !this.$refs.scroll) return;
      if (evt.type === 'touchmove') {
        const [touch] = evt.touches;
        this.$refs.scroll.scrollLeft -= touch.pageX - (this.lastTouch || touch.pageX);
        this.lastTouch = touch.pageX;
      } else {
        this.$refs.scroll.scrollLeft -= evt.movementX;
      }

      this.updateScroll();
    },

    touchDown() {
      this.scrollState = true;
    },

    touchUp() {
      this.lastTouch = false;
      this.scrollState = false;
    },

    resetCharacter() {
      this.$store.dispatch(MutationTypes.RESET_ASSET);
      this.storeNewCharacter();
    },

    checkLoad(evt) {
      this.imgLoad += 1;

      if (this.imgLoad === this.categories.length) {
        this.$emit('load', evt);
      }
    },

    scroll(direction) {
      if (direction === 'left') {
        this.$refs.scroll.scrollLeft -= 95;
      } else {
        this.$refs.scroll.scrollLeft += 95;
      }

      this.updateScroll();
    },

    updateScroll() {
      if (this.$refs.scroll.offsetWidth < this.$refs.scroll.scrollWidth) {
        this.scrollLeft = true;
        this.scrollRight = true;
      }

      if (this.$refs.scroll.scrollLeft === 0) {
        this.scrollLeft = false;
      }
      if (
        this.$refs.scroll.scrollLeft >= (
          this.$refs.scroll.scrollWidth
          - this.$refs.scroll.offsetWidth
        )
      ) {
        this.scrollRight = false;
      }
    },
  },

  watch: {
    character: {
      handler() {
        this.updateCategories();
      },
      deep: true,
    },
  },
};
</script>

<style>

.custom-container {
  overflow: hidden;
  min-width: calc(100% - 48px);
}

.heightforce {
  height: 115px;
}

.selector {
  order: 1;
  height: auto;
  overflow: hidden;
  width: 100%;
}

.selector-height {
  height: auto;
}

.selector > label {
  margin: 10px;
}

@media screen and (max-width: 1160px) {
  .merch .heightforce {
    height: 80px;
  }
}

@media screen and (max-width: 870px) {
  .heightforce {
    height: 80px;
  }
}

</style>
