<template>
  <div v-if="character">
    <div class="d-flex radio-select">
      <input type="radio" id="male" value="male" v-model="body">
      <label for="male" :class="{ checked: body === 'male' }">
        <icon icon="malebody"></icon>
      </label>
      <input type="radio" id="female" value="female" v-model="body">
      <label for="female" :class="{ checked: body === 'female' }">
        <icon icon="femalebody"></icon>
      </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import nearestColor from 'nearest-color';
import * as MutationTypes from '@/store/mutation-types';
import Asset from '@/models/asset';
import Skin from '@/models/skin';
import Icon from './Icon.vue';

export default {
  name: 'BodyType',

  components: { Icon },

  computed: {
    ...mapState({
      character: (state) => state.character.character,
    }),
  },

  data: () => ({
    body: 'malebody',
  }),

  methods: {
    async applyNewBody(asset, gender) {
      const [skinAsset] = this.get({
        type: 'Asset',
        category: 'bodies',
        subCategory: this.character.skin.color,
        anatomy: this.character.anatomy,
        gender,
      });

      const skin = new Skin({
        ...this.character.skin,
        assets: [new Asset(skinAsset)],
      });

      this.$store.dispatch(MutationTypes.UPDATE_GENDER, { gender, skin });

      asset = asset || this.generateRandomClothes();

      this.$store.dispatch(MutationTypes.UPDATE_ASSET, asset);

      this.$root.$emit('assets:update');
      this.storeNewCharacter();
    },
  },

  watch: {
    character: {
      handler(value) {
        this.body = value.gender;
      },

      deep: true,
    },

    body(value) {
      const oldCloth = this.character.assets.find((asset) => asset.category === 'clothes');

      if (!oldCloth) return;

      const clothes = this.get({
        type: 'Asset',
        category: 'clothes',
        gender: value,
        anatomy: this.character.anatomy,
        name: oldCloth.name,
      });

      if (clothes.length <= 0) {
        this.applyNewBody(null, value);
        return;
      }

      const newCloth = clothes[0];

      if (!oldCloth.activeVariant) {
        this.applyNewBody(new Asset(newCloth), value);
        return;
      }

      const variants = this.get({
        type: 'Variant',
        parent: newCloth._id,
      });

      const colors = {};
      variants.map((variant) => colors[variant._id] = `#${variant.color}`);
      const nearest = nearestColor.from(colors);

      const found = nearest(`#${oldCloth.activeVariant.color}`);

      const [variant] = this.get({
        type: 'Variant',
        _id: found.name,
      });

      newCloth.activeVariant = variant;

      this.applyNewBody(new Asset(newCloth), value);
    },
  },
};
</script>

<style>
</style>
