<template>
  <div class="centerer">
    <div class="navigator">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigator',
};
</script>

<style>

.centerer {
  width: 70%;
}

.navigator {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1160px) {
  .merch .centerer {
    width: 100%;
  }
}

@media screen and (max-width: 870px) {
  .centerer {
    width: 100%;
  }
}

</style>
