export default class Skin {
  constructor({
    id, assets, color,
  }) {
    this.id = id;
    this.assets = assets;
    this.color = color;
    this.colorId = parseInt(color, 0);
  }
}
